<template>
	<BaseLoading v-if="edit.isLoading" is-full-page />
	<AccordionSetsForm v-else is-edit />
</template>

<script>
import AccordionSetsForm from '@/components/AccordionSetsForm.vue';
import { mapActions, mapState } from 'vuex';
import { ROUTE_NAME } from '../enums/route';

export default {
	name: 'ProductDetailAccordionSetEdit',
	components: {
		AccordionSetsForm,
	},
	data() {
		return {};
	},

	computed: {
		...mapState('accordionSets', {
			edit: 'edit',
		}),
	},

	async created() {
		this.setBreadcrumbs([
			{
				title: 'Accordion Set',
				route: { name: ROUTE_NAME.PRODUCT_DETAIL_ACCORDION_SET_LIST },
			},
		]);
		await this.getAccordion(this.$route.params.id);
	},

	methods: {
		...mapActions({
			setBreadcrumbs: 'breadcrumb/setBreadcrumbs',
		}),
		...mapActions({
			getAccordion: 'accordionSets/getAccordionSet',
		}),
	},
};
</script>
